.homepage {
  background-color: black;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.alphabet {
  display: flex;
  flex-direction: row;

  .letter {
    height: 25px;
    width: 25px;
    font-size: 22px;
    padding: 5px;
    border: 1px solid white;
  }
}

.title {
  font-size: 40px;
}

.password-label {
  color: white;
  font-size: 10px;
  margin-bottom: 5px;
}

.puzzle2-text {
  color: white;
  font-size: 24px;
}

.sldkf {
  color: #003380;
}

.daksld {
  color: #f0a3ff;
}
.cxkcm {
  color: #0075dc;
}
.c {
  color: #993f00;
}
.lllkdd {
  color: #c20088;
}
.k {
  color: #8f7c00;
}
.l {
  color: #9dcc00;
}
.q {
  color: #426600;
}
.pekadf {
  color: #191919;
}
.wefds {
  color: #2bce48;
}
.f {
  color: #005c31;
}
.i {
  color: #808080;
}
.r {
  color: #ff0010;
}
.mdsdjfd {
  color: #5ef1f2;
}
.p {
  color: #ffa8bb;
}
.d {
  color: #4c005c;
}
.t {
  color: #00998f;
}
.v {
  color: #740aff;
}
.w {
  color: #990000;
}
.x {
  color: #ffff80;
}
.y {
  color: #ffff00;
}
.z {
  color: #ff5005;
}
.h {
  color: #ffcc99;
}
.j {
  color: #94ffb5;
}
.o {
  color: #ffa405;
}
.u {
  color: #e0ff66;
}
.word {
  margin: 5px;
  padding: 10px;
  background-color: white;
  font-weight: bolder;
  font-size: 32px;
}

.ladies {
  color: white;
}

.clue {
  color: white;
  font-size: 18px;
}
.words {
  display: flex;
  flex-direction: row;
}

.answer {
  margin: 5px;
}

.puzzle2,
.puzzle3,
.puzzle4 {
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mosh {
  color: white;
}
